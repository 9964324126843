import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import ComponentStyles from '../brand-styles/components/RecipeCarousel.styles';
import placeHolderImage from '../images/placeholder.png';
import { colors } from '../style-utilities/variables';

const StyledRecipeCollectionCard = styled(Link)`
  display: grid;
  display: -ms-grid;
  grid-template-columns: 1fr 1fr;
  -ms-grid-columns: 1fr 10px 1fr;
  grid-gap: 10px;
  grid-template-rows: repeat(12, 1fr);
  -ms-grid-rows: 1fr 1fr 1fr 1fr 1fr 1fr 10px 1fr 1fr 1fr 1fr 1fr 1fr;
  height: 100%;
  min-height: 300px;
  position: relative;
  background: none !important;

  .thumbnail {
    background-size: cover;
    background-position: center center;
  }

  .top-left,
  .top-right,
  .bottom {
    -ms-grid-row-span: 6;
  }

  .top-left,
  .top-right {
    grid-row: span 7;
  }

  .top-left {
    -ms-grid-column: 1;
    -ms-grid-row: 1;
    -ms-grid-column-span: 1;
  }
  .top-right {
    -ms-grid-column: 3;
    -ms-grid-row: 1;
    -ms-grid-column-span: 1;
  }

  .bottom {
    grid-column: span 2;
    -ms-grid-column: 1;
    -ms-grid-row: 8;
    -ms-grid-column-span: 3;
    grid-row: span 12;
  }

  .badge {
    bottom: 0px;
    color: white;
    display: inline-block;
    padding: 10px 10px 8px;
    position: absolute;
    font-size: 13px;

    &.count {
      right: 0;
      background: ${colors.secondary};
    }

    &.type {
      left: 0;
      background: gray;
    }
  }

  @media (min-width: 768px) {
    min-height: 500px;
  }

  @media (min-width: 1000px) {
    min-width: 600px;
  }

  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const RecipeCollectionCard = ({ recipes, slug }) => {
  const recipesWithImages = [];
  recipes.map(item => {
    if (item.node && item.node.acf.thumbnail) {
      recipesWithImages.push(item);
    }
  });
  recipesWithImages.slice(0, 3);
  return (
    <StyledRecipeCollectionCard
      to={`/recipe-collections/${slug}`}
      className="collection-images"
    >
      {recipesWithImages.map((item, index) => {
        const alignmentClass = ['top-left', 'top-right', 'bottom'];
        if (index < 3) {
          return (
            <div
              key={index}
              className={'thumbnail ' + alignmentClass[index]}
              style={{
                backgroundImage: `url(${
                  item.node.acf.thumbnail === null
                    ? placeHolderImage
                    : item.node.acf.thumbnail.source_url
                })`
              }}
            />
          );
        }
      })}

      <span className="type badge">Recipe Collection</span>
      <span className="count badge">{recipes.length} Recipes</span>
    </StyledRecipeCollectionCard>
  );
};

export default RecipeCollectionCard;
