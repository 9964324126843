import React, { Component, Fragment } from 'react';
import { graphql } from 'gatsby';
import { navigateTo } from 'gatsby-link';
import styled from 'styled-components';
import ComponentStyles from '../brand-styles/pages/recipe-search.styles';
import { TinyPagination } from 'react-pagination-custom';
import { Helmet } from 'react-helmet';

import PagesHero from '../components/PagesHero';
import Heading from '../components/Heading';
import FilterWidget from '../components/FilterWidget';
import FilterButton from '../components/FilterButton';
import { breakpoints, colors } from '../style-utilities/variables';
import RecipeCard from '../components/RecipeCard';
import RecipeCarousel from '../components/RecipeCarousel';
import PlaceHolderImage from '../images/placeholder.png';

const MainSection = styled.div`
  display: grid;
  display: -ms-grid;
  grid-template-columns: 30% 70%;
  -ms-grid-columns: 30% 70%;
  grid-template-areas: 'FilterWidget DisplayRecipe';
  padding: 40px 20px;
  .FilterWidget {
    grid-area: FilterWidget;
    -ms-grid-column: 1;
    grid-column: 1;
    .include-field {
    }
    .accordion:first-child {
      margin-top: 30px;
    }
  }
  div[class*='recipe-search'] {
    -ms-grid-column: 2;
    grid-column: 2;
  }

  @media (max-width: ${breakpoints.desktop}) {
    grid-template-columns: 25% 75%;
  }
`;

const DisplayRecipe = styled.div`
  grid-area: DisplayRecipe;
  #result-number {
    font-size: 25px;
    padding: 0 20px;
    margin: 0;
    p {
      font-size: 18px;
      font-weight: 700;
    }
  }
  .RecipeCard {
    display: inline-block;
    margin: 0;
    h1 {
      font-size: 20px;
    }
  }
  /* Pagination CSS */
  .btns-number-container {
    display: inherit;
  }
  .tiny-pagination-container {
    display: flex;
    .counter-container {
      display: none;
    }
    justify-content: center;
  }

  .counter-container {
    font-size: 18px;
  }
  .page {
    border: none;
    background: transparent;
  }
  .selected-page {
    color: red;
  }
  @media (min-width: ${breakpoints.tablet}) {
    .tiny-pagination-container {
      justify-content: flex-end;
    }
    .RecipeCard {
      margin: 10px;
    }
  }
`;

const Div = styled.div`
  .Hero {
    form {
      width: 70%;
      max-width: 500px;
      margin: 0 auto;
      input {
        width: 100%;
        margin: 0 auto;
        margin-top: 20px;
        border: 1px solid gray;
        color: #696969;
        border-radius: 4px;
      }
    }
  }
  .InnerBox {
    display: -ms-inline-flexbox;
    .title {
      margin: 0 auto;
      background: rgb(
        204,
        204,
        204
      ); /* Fallback for older browsers without RGBA-support */
      background: rgba(204, 204, 204, 0.7);
      z-index: 0;
      margin-bottom: 20px;
      h1 {
        margin: 0;
        padding: 15px 20px;
        color: #fff;
        font-size: 40px;
        z-index: 4;
      }
    }
  }
  .FilterButton {
    background: ${colors.secondary};
    color: #ffffff;

    svg {
      width: 30px;
      height: 20px;
    }
  }

  .FilterButton {
    display: none;
  }
  @media (max-width: ${breakpoints.tablet}) {
    ${MainSection} {
      display: block;
    }
    ${DisplayRecipe} {
      text-align: center;
      #result-number h3 {
        font-size: 20px;
        margin: 0;
      }
      .counter-container,
      .page {
        font-size: 14px;
      }
    }
    .RecipeCard {
      text-align: left;
    }
    .FilterButton {
      display: block;
      position: sticky;
      bottom: 20px;
      padding: 10px;
      font-size: 20px;
      width: 140px;
      margin: 0 auto 10px;
      cursor: pointer;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  /* Dynamic brand styles */
  ${ComponentStyles}
`;

class RecipeSearch extends Component {
  state = {
    searchValue: '',
    filterElements: [],
    excludedElements: [],
    includedElements: [],
    selectedPageId: 1,
    incIngValue: '',
    excIngValue: '',
    isFilterOpen: false,
    currentQueryString: null
  };
  //All Recipe Collections from CMS
  recipeCollections = this.props.data.allWordpressWpRecipeCollections.edges;
  /**
   * Pulling Array of recipes from CMS
   */
  recipes = this.props.data.allWordpressWpRecipes.edges;
  // Tags
  allTags = this.props.data.allWordpressTag.edges;
  // Product Line
  productLine = this.props.data.allWordpressWpProductLine.edges;
  // Pasta Shape - attribute 1
  pastaShape = this.props.data.allWordpressWpAttribute1.edges;
  // Main Ingredient
  mainIngredient = this.props.data.allWordpressWpMainIngredient.edges;
  //Cooking Method
  cookingMethod = this.props.data.allWordpressWpCookingMethod.edges;
  //Dish Type
  dishType = this.props.data.allWordpressWpDishType.edges;
  //Sauce
  sauce = this.props.data.allWordpressWpSauce.edges;
  // Meal / Course
  mealCourse = this.props.data.allWordpressWpMealCourse.edges;
  // Ready In
  readyIn = this.props.data.allWordpressWpReadyIn.edges;
  /**
   * Method: to check if item exists twice in an array
   */
  checkIfElementExist = (newArray, arraysOfItemsToAdd) => {
    if (arraysOfItemsToAdd) {
      arraysOfItemsToAdd.forEach(item => {
        if (!newArray.includes(item)) {
          newArray.push(item);
        }
      });
    }
  };

  componentDidMount() {
    this.updateFilters();
  }

  componentDidUpdate() {
    if (this.props.location.search !== this.state.currentQueryString) {
      this.setState({ currentQueryString: this.props.location.search });
      this.updateFilters();
    } else {
      this.updateQueryString();
    }
  }

  updateFilters = () => {
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    this.setState({ currentQueryString: this.props.location.search });
    /**
     * Get filtered by "Cook By" in Recipes Mega Menu
     */
    let items = params.get('items');
    let itemArr = [];
    if (items !== null) {
      itemArr = items.split(',');
      this.setState({ filterElements: itemArr });
    }

    let incItems = params.get('includes');
    let incItemArr = [];
    if (incItems !== null) {
      incItemArr = incItems.split(',');
      this.setState({ includedElements: incItemArr });
    }

    let excItems = params.get('excludes');
    let excItemArr = [];
    if (excItems !== null) {
      excItemArr = excItems.split(',');
      this.setState({ excludedElements: excItemArr });
    }

    /* Checks to see if a page is in the URL */
    let pagePosition = params.get('page');
    if (pagePosition !== null) {
      this.setState({ selectedPageId: parseInt(pagePosition) });
    }
  };

  updateQueryString = () => {
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    let updated = false;
    /**
     * Get filtered by "Cook By" in Recipes Mega Menu
     */
    let items = params.get('items');
    let newItems = this.state.filterElements
      ? this.state.filterElements.join()
      : '';
    if (
      (items && items !== newItems) ||
      (items && !newItems.length) ||
      (!items && newItems.length)
    ) {
      !newItems.length ? params.delete('items') : params.set('items', newItems);
      updated = true;
    }

    let incItems = params.get('includes');
    let newIncItems = this.state.includedElements
      ? this.state.includedElements.join()
      : '';
    if (
      (incItems && incItems !== newIncItems) ||
      (incItems && !newIncItems.length) ||
      (!incItems && newIncItems.length)
    ) {
      !newIncItems.length
        ? params.delete('includes')
        : params.set('includes', newIncItems);
      updated = true;
    }

    let excItems = params.get('excludes');
    let newExcItems = this.state.excludedElements
      ? this.state.excludedElements.join()
      : '';
    if (
      (excItems && excItems !== newExcItems) ||
      (excItems && !newExcItems.length) ||
      (!excItems && newExcItems.length)
    ) {
      !newExcItems.length
        ? params.delete('excludes')
        : params.set('excludes', newExcItems);
      updated = true;
    }

    if (updated) {
      window.history.pushState(
        null,
        '',
        this.props.location.pathname +
          (params.toString() ? '?' + params.toString() : '')
      );
      this.props.location.search = params.toString();
      this.setState({ currentQueryString: params.toString() });
    }
  };

  /**
   * METHOD: Pass State - filterElements to the FilterWidget Component
   * @param - filters are the filtering options
   */
  setFilters = filters => {
    this.setState({
      filterElements: filters
    });
  };

  /**
   * METHOD: Pass State for IncludedElements to the FilterWidget Component
   * @param - incArr are the included options from Include Ingredient Input
   */
  setIncludedIngFilters = incArr => {
    this.setState({
      includedElements: incArr
    });
  };

  /**
   * METHOD: Pass State for ExcludedElements to the FilterWidget Component
   * @param - excArr are the excluded options from Exclude Ingredient Input
   */
  setExcludedIngFilters = excArr => {
    this.setState({
      excludedElements: excArr
    });
  };
  /**
   * METHOD: Render recipes by filtering options
   */
  renderFilteredRecipe = () => {
    return this.recipes.map(
      ({
        node: {
          wordpress_id,
          title,
          content,
          acf,
          slug,
          attribute_1,
          main_ingredient,
          meal_course,
          cooking_method,
          dish_type,
          sauce,
          product_line,
          ready_in,
          tags
        }
      }) => {
        //Get Slugs for taxonomy "Main Ingredient"
        const mainIngredientSlugs = this.mainIngredient.filter(el =>
          main_ingredient.some(e => e === el.node.wordpress_id)
        );
        //Get Slugs for taxonomy "Meal Course"
        const mealCourseSlugs = this.mealCourse.filter(el =>
          meal_course.some(e => e === el.node.wordpress_id)
        );
        //Get Slugs for taxonomy "Main Ingredient"
        const readyInSlugs = this.readyIn.filter(el =>
          ready_in.some(e => e === el.node.wordpress_id)
        );
        //Get Slugs for taxonomy Cooking Method
        let cookingMethodSlugs;
        if (cooking_method !== []) {
          cookingMethodSlugs = this.cookingMethod.filter(el =>
            cooking_method.some(e => e === el.node.wordpress_id)
          );
        }
        // Get slugs for taxonomy product_line
        const productLineSlugs = this.productLine.filter(el =>
          product_line.some(e => e === el.node.wordpress_id)
        );
        // Get slugs for taxonomy dish type
        const dishTypeSlugs = this.dishType.filter(el =>
          dish_type.some(e => e === el.node.wordpress_id)
        );
        // Get slugs for taxonomy sauce
        const sauceSlugs = this.sauce.filter(el =>
          sauce.some(e => e === el.node.wordpress_id)
        );
        // Get slugs for taxonomy attribute 1 - pasta shape
        let pastaShapeSlugs;
        if (attribute_1 !== []) {
          pastaShapeSlugs = this.pastaShape.filter(el =>
            attribute_1.some(e => e === el.node.wordpress_id)
          );
        }

        const allSlugs = [
          ...mainIngredientSlugs,
          ...mealCourseSlugs,
          ...readyInSlugs,
          ...cookingMethodSlugs,
          ...productLineSlugs,
          ...dishTypeSlugs,
          ...sauceSlugs
        ];
        //display by default
        let display = true;

        // see if this matches excluded ingredients
        if (
          this.state.excludedElements &&
          this.state.excludedElements.length > 0 &&
          (allSlugs.some(el =>
            this.state.excludedElements.includes(el.node.slug)
          ) ||
            this.state.excludedElements.some(el =>
              title.toLowerCase().includes(el)
            ) ||
            (acf.ingredient_products
              ? acf.ingredient_products.some(el =>
                  this.state.excludedElements.some(el2 =>
                    el.basic_ingredient
                      .toLowerCase()
                      .includes(el2.toLowerCase())
                  )
                )
              : false))
        ) {
          display = false;
        }

        // see if this matches included ingredients
        if (
          (this.state.filterElements &&
            this.state.filterElements.length > 0 &&
            !allSlugs.some(el =>
              this.state.filterElements.includes(el.node.slug)
            ) &&
            !tags.some(el => this.state.filterElements.includes(el.slug)) &&
            !this.state.filterElements.some(el =>
              title.toLowerCase().includes(el)
            ) &&
            !this.state.filterElements.some(el =>
              content.toLowerCase().includes(el)
            ) &&
            (acf.ingredient_products
              ? !acf.ingredient_products.some(el =>
                  this.state.filterElements.some(el2 =>
                    el.basic_ingredient.toLowerCase().includes(el2)
                  )
                )
              : false)) ||
          (this.state.includedElements &&
            this.state.includedElements.length > 0 &&
            !allSlugs.some(el =>
              this.state.includedElements.includes(el.node.slug)
            ) &&
            !allSlugs.some(el =>
              this.state.includedElements.includes(
                el.node.slug.replace('veggie', 'vegetarian')
              )
            ) &&
            !allSlugs.some(el =>
              this.state.includedElements.includes(
                el.node.slug.replace(/\-+/g, ' ')
              )
            ) &&
            !tags.some(el => this.state.includedElements.includes(el.slug)) &&
            !this.state.includedElements.some(el =>
              title.toLowerCase().includes(el)
            ) &&
            !this.state.includedElements.some(el =>
              content.toLowerCase().includes(el)
            ) &&
            (acf.ingredient_products
              ? !acf.ingredient_products.some(el =>
                  this.state.includedElements.some(el2 =>
                    el.basic_ingredient.toLowerCase().includes(el2)
                  )
                )
              : false))
        ) {
          display = false;
        }

        // check for tags
        if (
          !tags.some(el => this.state.filterElements.includes(el.slug)) &&
          this.allTags.some(el =>
            this.state.filterElements.includes(el.node.slug)
          )
        ) {
          display = false;
        }

        //check main ingredient
        if (
          !mainIngredientSlugs.some(el =>
            this.state.filterElements.includes(el.node.slug)
          ) &&
          this.mainIngredient.some(el =>
            this.state.filterElements.includes(el.node.slug)
          )
        ) {
          display = false;
        }

        //check meal course
        if (
          !mealCourseSlugs.some(el =>
            this.state.filterElements.includes(el.node.slug)
          ) &&
          this.mealCourse.some(el =>
            this.state.filterElements.includes(el.node.slug)
          )
        ) {
          display = false;
        }

        //check cooking method
        if (
          !cookingMethodSlugs.some(el =>
            this.state.filterElements.includes(el.node.slug)
          ) &&
          this.cookingMethod.some(el =>
            this.state.filterElements.includes(el.node.slug)
          )
        ) {
          display = false;
        }

        //check dish type
        if (
          !dishTypeSlugs.some(el =>
            this.state.filterElements.includes(el.node.slug)
          ) &&
          this.dishType.some(el =>
            this.state.filterElements.includes(el.node.slug)
          )
        ) {
          display = false;
        }

        //check sauce

        if (
          !sauceSlugs.some(el =>
            this.state.filterElements.includes(el.node.slug)
          ) &&
          this.sauce.some(el =>
            this.state.filterElements.includes(el.node.slug)
          )
        ) {
          display = false;
        }

        //check product line

        if (
          !productLineSlugs.some(el =>
            this.state.filterElements.includes(el.node.slug)
          ) &&
          this.productLine.some(el =>
            this.state.filterElements.includes(el.node.slug)
          )
        ) {
          display = false;
        }

        //check ready in
        if (
          !readyInSlugs.some(el =>
            this.state.filterElements.includes(el.node.slug)
          ) &&
          this.readyIn.some(el =>
            this.state.filterElements.includes(el.node.slug)
          )
        ) {
          display = false;
        }

        //check Pasta Shape
        if (
          !pastaShapeSlugs.some(el =>
            this.state.filterElements.includes(el.node.slug)
          ) &&
          this.pastaShape.some(el =>
            this.state.filterElements.includes(el.node.slug)
          )
        ) {
          display = false;
        }

        if (display) {
          return (
            <RecipeCard
              updateFavoritesCount={this.props.userContext.updateFavoritesCount}
              authenticatedUser={this.props.userContext.authenticatedUser}
              slug={slug}
              className="RecipeCard"
              key={wordpress_id}
              id={wordpress_id}
              title={title}
              imageUrl={
                acf.thumbnail === null
                  ? PlaceHolderImage
                  : acf.thumbnail.source_url
              }
              mainIngredient={
                mainIngredientSlugs[0] ? mainIngredientSlugs[0].node.name : ''
              }
              cookTime={acf.total_time}
            />
          );
        }
      }
    );
  };

  /**
   * METHOD: Delete all tags including included/excluded ingredient input fields
   */
  deleteAllFilteredEle = () => {
    const emptyArr = [];
    this.setFilters(emptyArr);
    if (this.state.includedElements || this.state.excludedElements) {
      this.setIncludedIngFilters(emptyArr);
      this.setExcludedIngFilters(emptyArr);
    }
  };
  /**
   * METHOD: Submit form for recipe search box
   */
  submitHandler = e => {
    e.preventDefault();
    this.deleteAllFilteredEle();
    if (this.state.searchValue !== '') {
      const value = this.state.searchValue.toLowerCase();
      this.setState({
        includedElements: [value]
      });
    }
    e.target.reset();
  };

  /**
   * METHOD: Scroll to top on pagination click
   */
  scrollStep() {
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
  }

  scrollToTop() {
    let intervalId = setInterval(
      this.scrollStep.bind(this),
      this.props.delayInMs
    );
    this.setState({ intervalId: intervalId });
  }

  /**
   * METHOD: Change page on Pagination
   * @param: id of the current page
   */
  changePage(id) {
    this.setState(
      prevState => {
        return {
          ...prevState,
          selectedPageId: id
        };
      },
      () => {
        const { pathname } = this.props.location;
        const url = `${pathname}?page=${this.state.selectedPageId}`;
        navigateTo(url);
      }
    );
  }

  /**
   * METHOD: for button Prev and Next on Pagination
   * @param: id of the current page
   */
  buttonPageClick(id) {
    let { selectedPageId } = this.state;
    switch (id) {
      case 'Prev':
        this.changePage(selectedPageId - 1);
        break;
      case 'Next':
        this.changePage(selectedPageId + 1);
        break;
      default:
        this.changePage(id);
        break;
    }
    this.scrollToTop();
  }

  /**
   * METHOD: Render button to the UI
   * @param: id of the current page
   */
  renderBtnNumber = id => {
    let { selectedPageId } = this.state;
    return (
      <button
        onClick={this.buttonPageClick.bind(this, id)}
        key={id}
        className={`page ${selectedPageId === id ? 'selected-page' : ''}`}
      >
        {id}
      </button>
    );
  };

  /**
   * METHODs: Getting value from input boxes
   */
  onChangeHandler = e => {
    const value = e.target.value;
    this.setState({ [e.target.name]: value });
  };
  incIngredientSubmit = event => {
    event.preventDefault();
    if (this.state.incIngValue) {
      // let includeArr = [...this.state.includedElements];
      let includeArr = [];
      includeArr.push(this.state.incIngValue.toLowerCase());
      this.checkIfElementExist(includeArr, this.state.includedElements);
      this.setState({ includedElements: includeArr });
    }
    event.target.reset();
  };
  excIngredientSubmit = event => {
    event.preventDefault();
    if (this.state.excIngValue) {
      // let excludeArr = [...this.state.excludedElements];
      let excludeArr = [];
      excludeArr.push(this.state.excIngValue.toLowerCase());
      this.checkIfElementExist(excludeArr, this.state.excludedElements);
      this.setState({ excludedElements: excludeArr });
    }
    event.target.reset();
  };

  /**
   * METHOD: Toggle Filter Widget for Mobile Version
   */
  filterToggleHandler = () => {
    this.setState({ isFilterOpen: !this.state.isFilterOpen });
  };

  render() {
    const mainIngredients = this.mainIngredient.map(item => item);
    const mealCourse = this.mealCourse.map(item => item);
    const cookingMethod = this.cookingMethod.map(item => item);
    const dishType = this.dishType.map(item => item);
    const sauce = this.sauce.map(item => item);
    const productLine = this.productLine.map(item => item);
    const readyIn = this.readyIn.map(item => item);
    /**
     * FILTER OBJECT FOR FILTER WIDGET
     */
    const filterObj = {
      'Main Ingredient': mainIngredients,
      'Meal Part': mealCourse,
      'Cooking Method': cookingMethod,
      'Dish Type': dishType,
      'By Sauce': sauce,
      'Recipes by Pasta Line': productLine,
      'Ready in': readyIn
    };
    // const mergedObj = { ...filterObj, ...this.state.productFilterObj };
    const ingredientInput = (
      <Fragment>
        <form onSubmit={this.incIngredientSubmit}>
          <div className="include-field">
            <input
              name="incIngValue"
              type="text"
              placeholder="Include Ingredients"
              onChange={this.onChangeHandler}
            />
            <button
            // disabled={!this.state.incIngValue}
            >
              +
            </button>
          </div>
        </form>

        <form onSubmit={this.excIngredientSubmit}>
          <div className="include-field">
            <input
              name="excIngValue"
              type="text"
              placeholder="Exclude Ingredients"
              onChange={this.onChangeHandler}
            />
            <button
            // disabled={!this.state.excIngValue}
            >
              -
            </button>
          </div>
        </form>
      </Fragment>
    );
    let filteredRecipeResults = this.renderFilteredRecipe().filter(
      element => typeof element !== 'undefined'
    );
    const itemPerPage = 12;

    if (
      filteredRecipeResults.length <
      (this.state.selectedPageId - 1) * itemPerPage
    ) {
      this.changePage(1);
    }

    let filteredRecipePerPage = [...filteredRecipeResults];
    filteredRecipePerPage = filteredRecipePerPage.splice(
      (this.state.selectedPageId - 1) * itemPerPage,
      itemPerPage
    );

    // Hero Image
    const heroImage =
      this.props.data.wordpressPage.acf.hero_image === null
        ? PlaceHolderImage
        : this.props.data.wordpressPage.acf.hero_image.source_url;

    return (
      <Div>
        <Helmet>
          <title>
            {this.props.data.wordpressPage.yoast_head_json.title}
          </title>
          <meta
            name="description"
            content={
              this.props.data.wordpressPage.yoast_head_json.description
            }
          />
        </Helmet>
        <PagesHero image={heroImage} className="Hero">
          <div className="InnerBox">
            <div className="title">
              <h1>Recipe Search</h1>
            </div>
          </div>

          <form onSubmit={this.submitHandler}>
            <input
              name="searchValue"
              className="search-form"
              placeholder="Search All Recipes"
              onChange={this.onChangeHandler}
            />
          </form>
        </PagesHero>
        <MainSection>
          <div className="FilterWidget">
            <FilterWidget
              title="Filter Recipes"
              state={this.state.filterElements}
              excludedIngredients={this.state.excludedElements}
              includedIngredients={this.state.includedElements}
              setFilters={this.setFilters}
              setExcludedIngFilters={this.setExcludedIngFilters}
              setIncludedIngFilters={this.setIncludedIngFilters}
              filterList={filterObj}
              tagTitle=""
              includeExcludeTitle="Include / Exclude Ingredients"
              ingredientInput={ingredientInput}
              filterOpen={this.state.isFilterOpen}
              filterToggle={this.filterToggleHandler}
            />
          </div>

          <DisplayRecipe>
            <>
              <div id="result-number">
                <p>{filteredRecipeResults.length} Results</p>
              </div>

              {filteredRecipePerPage}
              {filteredRecipeResults.length > itemPerPage ? (
                <TinyPagination
                  total={filteredRecipeResults.length}
                  selectedPageId={this.state.selectedPageId}
                  itemPerPage={itemPerPage}
                  renderBtnNumber={this.renderBtnNumber}
                  maxBtnNumbers={10}
                  preKey="Prev"
                  nextKey="Next"
                />
              ) : (
                ''
              )}
            </>
          </DisplayRecipe>
        </MainSection>
        <FilterButton
          className="FilterButton"
          filterToggle={this.filterToggleHandler}
        />
        <RecipeCarousel
          data={this.recipeCollections}
          allRecipes={this.recipes}
        />
      </Div>
    );
  }
}

export default RecipeSearch;

export const query = graphql`
  query {
    wordpressPage(slug: { eq: "recipe-search" }) {
      yoast_head_json {
        title
        description
      }
      acf {
        hero_image {
          source_url
        }
      }
    }
    allWordpressWpTaxonomies {
      edges {
        node {
          name
          slug
          types
        }
      }
    }
    allWordpressTag {
      edges {
        node {
          slug
        }
      }
    }
    allWordpressWpProductLine {
      edges {
        node {
          name
          slug
          wordpress_id
        }
      }
    }
    allWordpressWpAttribute1 {
      edges {
        node {
          name
          slug
          wordpress_id
        }
      }
    }
    allWordpressWpMealCourse {
      edges {
        node {
          name
          wordpress_id
          slug
        }
      }
    }
    allWordpressWpMainIngredient {
      edges {
        node {
          name
          wordpress_id
          slug
        }
      }
    }
    allWordpressWpCookingMethod {
      edges {
        node {
          name
          wordpress_id
          slug
        }
      }
    }
    allWordpressWpDishType {
      edges {
        node {
          name
          wordpress_id
          slug
        }
      }
    }
    allWordpressWpSauce {
      edges {
        node {
          name
          wordpress_id
          slug
        }
      }
    }
    allWordpressWpReadyIn {
      edges {
        node {
          name
          wordpress_id
          slug
        }
      }
    }
    allWordpressWpRecipes {
      edges {
        node {
          slug
          title
          content
          wordpress_id
          tags {
            slug
          }
          product_line
          attribute_1
          ready_in
          main_ingredient
          meal_course
          cooking_method
          dish_type
          sauce
          acf {
            thumbnail {
              source_url
            }
            total_time
            ingredient_products {
              basic_ingredient
            }
            related_product {
              post_name
            }
          }
          ready_in
        }
      }
    }
    allWordpressWpRecipeCollections {
      edges {
        node {
          slug
          wordpress_id
          title
          content
          acf {
            recipes {
              recipe {
                post_name
              }
            }
          }
        }
      }
    }
  }
`;
