import { css } from 'styled-components';
import { fonts } from '../../style-utilities/variables';
export default css`
  #results-number {
    p {
      font-family: ${fonts.secondary}, sans-serif;
    }
  }

  .InnerBox {
    display: -ms-inline-flexbox;
    .title {
      background:none;
      h2 {
        color: rgb(190, 13, 52);
        font-size: 36px;
        margin-bottom: 0px;
        text-align: center;
        font-family: Poppins, sans-serif;
      }
    }
  }
`;
