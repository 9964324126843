import { css } from 'styled-components';
import { breakpoints } from '../../style-utilities/variables';

export default css`
  padding: 0 !important;
  .RecipeContent {
    text-align: left;
    h2 {
      font-size: 40px;
      margin: 0px;
      font-family: Poppins, sans-serif;
      color: rgb(190, 13, 52);
    }
    .center {
      #view-button {
        padding: 10px 10px 5px 10px !important;
        text-align: center;
      }
    }
  }
  @media (max-width: ${breakpoints.mobileMax}) {
    .RecipeContent {
      padding: 10px 5px 40px 5px !important;
    }
  }
  @media (min-width: ${breakpoints.tablet}) {
    .RecipeContent {
      padding: 10px 5px 20px 5px !important;
    }
  }
`;
