import React, { Component } from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import ComponentStyles from '../brand-styles/components/RecipeCarousel.styles';
import Swiper from 'react-id-swiper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';

import { breakpoints } from '../style-utilities/variables';
import Heading from '../components/Heading';
import placeHolderImage from '../images/placeholder.png';
import RecipeCollectionCard from '../components/RecipeCollectionCard';

import StyledLink from '../components/StyledLink';
import arrowLeft from '../images/arrow-left.png';
import arrowRight from '../images/arrow-right.png';

const StyledCarousel = styled.div`
  margin: 0 auto;
  padding: 20px 20px 0;
  .center {
    text-align: center;
  }
  h1 {
    font-size: 20px;
  }
  text-align: center;
  .swiper-slide {
    margin: 0 auto;
  }
  p {
    line-height: 1.4;
  }
  .CollectionCard {
    @media (-ms-high-contrast: none) {
      display: flex;
    }
  }
  @media (min-width: 768px) {
    padding: 50px;
    text-align: center;
    .CollectionCard {
      margin-bottom: 40px;
    }
    .center {
      text-align: left;
    }
    img {
      margin: auto;
    }
    .RecipeContent {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      h1 {
        margin: 0;
        font-size: 40px;
      }
      span {
        font-size: 18px;
        margin-top: 10px;
      }
      #view-button {
        padding: 10px !important;
        width: 13rem;
      }
    }
  }

  @media (min-width: 1100px) {
    padding: 80px 8% 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    .CollectionCard {
      margin-right: 40px;
    }
    .RecipeContent {
      width: 48%;
      p {
        font-size: 20px;
      }
    }
  }
`;

const Div = styled.div`
  margin: 0 auto;
  padding: 40px 0 30px;
  height: auto;
  .swiper-pagination {
    display: none;
  }
  @media (max-width: ${breakpoints.tablet}) {
    .swiper-pagination {
      display: block;
    }
    ${StyledCarousel} {
      /* padding: 20px 15px 0; */
      display: block;
      img {
        width: 250px;
        height: 200px;
      }
      .RecipeContent {
        padding: 10px;
        text-align: left;
        h1 {
          font-size: 18px;
        }
      }
    }
  }

  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const NavigationButton = styled.button`
  display: none;
  @media (min-width: 768px) {
    display: block;
    position: absolute;
    top: 47%;
    right: ${props => props.rightTablet};
    left: ${props => props.leftTablet};
    margin-top: -22px;
    z-index: 10;
    cursor: pointer;
    background-color: transparent;
    border: none;
    background-position: center;
    background-repeat: no-repeat;
    @media (max-width: ${breakpoints.tabletMax}) {
      top: 50%;
    }

    img {
      height: 30px;
      width: 22px;
      opacity: 0.8;
      cursor: pointer;
    }
  }
  @media (min-width: ${breakpoints.desktop}) {
    right: ${props => props.rightDesktop};
    left: ${props => props.leftDesktop};
  }
`;

class RecipeCarousel extends Component {
  allRecipes = this.props.allRecipes;
  /**
   * METHOD: Render Recipe Collection Carousel
   */
  renderCarousel = () => {
    return this.props.data.map(
      ({
        node: {
          slug,
          wordpress_id,
          title,
          content,
          acf: { recipes }
        }
      }) => {
        const collectionRecipes = this.allRecipes.filter(recipe =>
          recipes.some(el => el.recipe.post_name === recipe.node.slug)
        );
        return (
          <StyledCarousel key={wordpress_id} className="styled-carousel">
            <div className="CollectionCard">
              <RecipeCollectionCard recipes={collectionRecipes} slug={slug} />
            </div>
            <div className="RecipeContent">
              <h2>{ReactHtmlParser(title)}</h2>
              <span>{ReactHtmlParser(content)}</span>
              <div className="center">
                <StyledLink
                  link={`/recipe-collections/${slug}`}
                  id="view-button"
                >
                  View this Collection
                </StyledLink>
              </div>
            </div>
          </StyledCarousel>
        );
      }
    );
  };
  render() {
    const { children, data, ...remainProps } = this.props;
    const params = {
      crossFade: true,
      loop: true,
      type: 'bullets',
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      navigation: {
        nextEl: '.next-button',
        prevEl: '.prev-button'
      },
      renderNextButton: () => (
        <NavigationButton
          className="next-button"
          rightTablet="25px"
          rightDesktop="5%"
        >
          <img src={arrowRight} alt="right" />
        </NavigationButton>
      ),
      renderPrevButton: () => (
        <NavigationButton
          className="prev-button"
          leftTablet="25px"
          leftDesktop="5%"
        >
          <img src={arrowLeft} alt="left" />
        </NavigationButton>
      )
    };

    return (
      <Div {...remainProps}>
        {children}
        <Swiper {...params}>{this.renderCarousel()}</Swiper>
      </Div>
    );
  }
}

export default RecipeCarousel;
